import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import mapValues from "lodash/mapValues";

import { IndicatorForm } from "./components";
import useIndicatorSettings from "shared/entities/bot/model/useIndicatorSettings";

const validationSchema = Yup.object().shape({
  settings: Yup.lazy(obj =>
    Yup.object(
      mapValues(obj, value => {
        if (!value) return Yup.string().required();
        return Yup.mixed().notRequired();
      }),
    ),
  ),
});

const IndicatorSettingsBlock = ({
  draft,
  isEdit,
  toogleBlock,
  getIndicatorsConfiguration,
  editingIndicator = {},
  isShowCreationBlock,
}) => {
  const {
    configurations,
    currentConfiguration,
    onHandleResetChart,
    handleSubmit,
    setSelectedMarket,
    selectedMarket,
    subscription,
    wizardMarkets,
    currentSettings,
    setIndicatorType,
    indicatorType,
  } = useIndicatorSettings(
    isEdit,
    getIndicatorsConfiguration,
    draft,
    editingIndicator,
    toogleBlock,
    isShowCreationBlock,
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        code: currentConfiguration.code || "",
        type: editingIndicator.type || "both",
        required: editingIndicator.required || false,
        settings:
          isEdit && editingIndicator.code === indicatorType
            ? editingIndicator.settings
            : currentSettings,
      }}
      validationSchema={
        Object.keys(currentSettings).length ? validationSchema : null
      }
      onSubmit={e => e.preventDefault()}
    >
      {({ errors, isSubmitting, values, setFieldValue, setErrors }) => (
        <IndicatorForm
          indicatorType={indicatorType}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onHandleResetChart={onHandleResetChart}
          isShowCreationBlock={isShowCreationBlock}
          currentConfiguration={currentConfiguration}
          onSetIndicatorType={setIndicatorType}
          configurations={configurations}
          isSubmitting={isSubmitting}
          isEdit={isEdit}
          setErrors={setErrors}
          subscription={subscription}
          setSelectedMarket={setSelectedMarket}
          wizardMarkets={wizardMarkets}
          selectedMarket={selectedMarket}
          handleSubmit={handleSubmit}
          toogleBlock={onHandleResetChart}
        />
      )}
    </Formik>
  );
};

export default IndicatorSettingsBlock;
