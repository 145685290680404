import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import { getIndicatorsConfiguration } from "store/indicatorsConfiguration/actions";
import { AddButton } from "shared/ui/buttons";
import { Indicator, IndicatorsHeader } from "./components";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { IndicatorSettingsBlock } from "features/blocks";

const Indicators = ({
  isEdit,
  buySignals,
  sellSignals,
  submitted,
  setIsOpenSettings,
  botIndicators,
}) => {
  const [isShowCreationBlock, setIsShowCreationBlock] = useState(false);
  const [isEditionIndicator, setIsEditionIndicator] = useState(false);

  const indicatorsList = useSelector(state => wizardIndicatorsSelector(state));
  const { values, errors, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  const indicators = [...(botIndicators || []), ...indicatorsList];

  useEffect(() => {
    dispatch(getIndicatorsConfiguration());
  }, [dispatch]);

  const toogleShowCreationBlock = () => {
    setIsShowCreationBlock(prev => !prev);
    setIsOpenSettings(prev => !prev);
  };

  return (
    <div data-testid='Indicators-wrapper' className='pt-3'>
      <IndicatorsHeader
        sellSignals={sellSignals}
        buySignals={buySignals}
        values={values}
        errors={errors}
        submitted={submitted}
        setFieldValue={setFieldValue}
      />
      {indicators?.map(indicator => (
        <Indicator
          isEdit={isEdit}
          key={indicator.id}
          indicator={indicator}
          setIsEditionIndicator={setIsEditionIndicator}
          isEditionIndicator={isEditionIndicator}
          setIsOpenSettings={setIsOpenSettings}
        />
      ))}

      {isShowCreationBlock ? (
        <IndicatorSettingsBlock
          draft
          isShowCreationBlock={isShowCreationBlock}
          getIndicatorsConfiguration={getIndicatorsConfiguration}
          toogleBlock={toogleShowCreationBlock}
        />
      ) : (
        <AddButton
          array={indicators}
          maxLength={10}
          isDisabled={isEditionIndicator}
          addHover={!isEditionIndicator}
          className='w-100'
          classNameButtonLabel='ml-2 font-sm font-weight-400 text-muted-indigo'
          buttonLabel='Add new indicator'
          bgButton='dusky-graphite'
          toogleEdit={toogleShowCreationBlock}
        />
      )}
    </div>
  );
};

export default Indicators;
