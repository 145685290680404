import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectindicatorsSignals } from "store/bots/selectors";
import { ErrorAlert } from "shared/ui";
import { IndicatorFormChartBlock, IndicatorFormSettingsBlock } from "./ui";
import IndicatorSetting from "views/indicators/IndicatorSetting";
import { indicatorsConfiguration } from "views/bots/wizard/Configuration/Indicators/models/indicatorsConfiguration";
import { StyledIndicatorFormWrapper } from "../../index.styles";
import { IndicatorFormBTNGroup } from "./ui/IndicatorFormSettingsBlock/ui";
import { indicatorsSignals } from "store/bots/actions";

const IndicatorForm = ({
  values,
  setFieldValue,
  errors,
  currentConfiguration,
  onSetIndicatorType,
  configurations,
  isEdit,
  setErrors,
  subscription,
  setSelectedMarket,
  wizardMarkets,
  selectedMarket,
  handleSubmit,
  toogleBlock,
  indicatorType,
}) => {
  const [submited, setSubmitted] = useState(false);
  const { data, loading, error } = useSelector(selectindicatorsSignals);

  const dispatch = useDispatch();
  useEffect(() => {
    if (indicatorType && selectedMarket) {
      const timeoutId = setTimeout(() => {
        const param = {
          period: wizardMarkets?.period,
          ...values,
        };
        dispatch(
          indicatorsSignals(
            wizardMarkets?.investment?.credentials?.exchange,
            selectedMarket,
            wizardMarkets?.investment?.asset,
            indicatorType,
            param,
          ),
        );
      });

      return () => clearTimeout(timeoutId);
    }
  }, [
    indicatorType,
    selectedMarket,
    wizardMarkets?.period,
    wizardMarkets?.investment?.credentials?.exchange,
    wizardMarkets?.investment?.asset,
    values,
    dispatch,
  ]);

  return (
    <StyledIndicatorFormWrapper data-testid='indicator-form-wrapper'>
      <IndicatorFormSettingsBlock
        formValues={values}
        setFieldValue={setFieldValue}
        indicatorsConfiguration={indicatorsConfiguration}
        submitted={submited}
        configurations={configurations}
        subscription={subscription}
        isEdit={isEdit}
        toogleEdit={toogleBlock}
        handleSubmit={handleSubmit}
        onSetIndicatorType={onSetIndicatorType}
        setSubmitted={setSubmitted}
      />
      <IndicatorSetting
        currentConfiguration={currentConfiguration}
        setFieldValue={setFieldValue}
        errors={errors}
        submited={submited}
        values={values}
      />
      <IndicatorFormChartBlock
        wizardMarkets={wizardMarkets}
        loading={loading}
        error={error}
        chartData={data}
        setSelectedMarket={setSelectedMarket}
      />
      <ErrorAlert
        fade={false}
        toggle={() => setErrors({})}
        isOpen={error?.settings && Object.keys(error?.settings).length}
      >
        Please check if you have filled up all fields correctly and try again
      </ErrorAlert>
      <IndicatorFormBTNGroup
        className='d-flex justify-content-between w-100 d-md-none'
        isEdit={isEdit}
        toogleEdit={toogleBlock}
        handleSubmit={handleSubmit}
        formValues={values}
      />
    </StyledIndicatorFormWrapper>
  );
};

export default IndicatorForm;
