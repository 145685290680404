import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Tooltip } from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { enableBot, disableBot, updateName } from "store/bots/actions";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";
import {
  HeaderPreLoader,
  Icon,
  IdWithCopy,
  LineWithError,
  NameInlineEditor,
  ToggleSwitch,
} from "shared/ui";

const BotDetailsHeader = ({ id, data, loading }) => {
  const [tooltipDateOpen, setTooltipDateOpen] = useState(false);
  const { status, createdAt, type } = data;

  const location = useLocation();
  const isEdit = location.pathname.includes("configure");

  const dispatch = useDispatch();

  const toggleDate = () => setTooltipDateOpen(!tooltipDateOpen);

  const handleToggleBot = () => {
    if (status === "running") {
      dispatch(disableBot(id));
    } else {
      dispatch(enableBot(id));
    }
  };

  if (loading) return <HeaderPreLoader />;

  return (
    <Card className='mb-3'>
      <CardBody className='p-4'>
        <BotDetailsHeader.Wrapper className='d-flex flex-column flex-md-row justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <Icon size='botSize' code={`bot/${type}-bot`} type='icons' />
                <NameInlineEditor
                  name={data.name}
                  id={id}
                  className='pl-2'
                  onChangeName={updateName}
                />
              </div>
              <IdWithCopy id={id} className='d-none d-md-flex' />
            </div>
          </div>

          <div className='flex-row flex-md-column my-auto position-relative'>
            <div className='d-flex justify-content-start justify-content-md-end'>
              <BotDetailsHeader.Status
                className='d-none d-md-block'
                status={status}
              >
                {status === "running" ? "Enabled" : "Disabled"}
              </BotDetailsHeader.Status>
              {!isEdit && (
                <ToggleSwitch
                  onChange={handleToggleBot}
                  checked={status === "running"}
                  height={18}
                  width={45}
                  onColor='#26366b'
                  offColor='#1c284d'
                  onHandleColor='#3fcbb0'
                  offHandleColor='#ff8d72'
                  className='my-md-auto ml-0 mt-2 mt-md-0 ml-md-2'
                />
              )}
            </div>
            <p className='mt-2 d-flex justify-content-start justify-content-md-end align-items-center text-white-35 font-xs'>
              <Clock
                className='icon-stroke-gray mr-1'
                id='date'
                aria-hidden='true'
              />
              {moment(createdAt).format("DD.MM.YY HH:mm")}
            </p>
            <div className='position-absolute left-0 top-0'>
              <Tooltip
                target='date'
                placement='top'
                isOpen={tooltipDateOpen}
                toggle={toggleDate}
              >
                Date of creation
              </Tooltip>
            </div>
          </div>
        </BotDetailsHeader.Wrapper>
      </CardBody>
      <LineWithError errorMessage={data?.error} />
    </Card>
  );
};

BotDetailsHeader.Wrapper = styled.div`
  .tooltip-inner {
    padding: 10px !important;
    border-bottom-left-radius: 0 !important;
  }
`;

BotDetailsHeader.Status = styled.p`
  background: rgba(255, 255, 255, 0.05);
  color: ${props =>
    props.status === "running"
      ? "#3FCBB0"
      : "rgba(255, 255, 255, 0.35)"} !important;
  padding: 2px 16px;
  border-radius: 8px;
  margin-bottom: 0;
  font-size: 14px;
`;

export default BotDetailsHeader;
