import React from "react";
import { Label } from "reactstrap";
import { useSelector } from "react-redux";

import { SelectInput } from "shared/ui";
import CandlestickChart from "../CandlestickChart";
import { selectMarketsSignal } from "store/bots/selectors";

const IndicatorFormChartBlock = ({
  error,
  chartData,
  setSelectedMarket,
  loading,
}) => {
  const {
    data: { items },
    loading: loadingMarkets,
  } = useSelector(selectMarketsSignal);

  return (
    <div className='d-flex flex-column w-100'>
      <Label className='mb-2 text-white-35 font-weight-300'>
        Select market
      </Label>
      <SelectInput
        isClearable
        className='w-100 mb-1'
        placeholder='Market'
        isLoading={loadingMarkets}
        onChange={values => {
          setSelectedMarket(values?.icon?.code);
        }}
        options={
          items?.length
            ? items.map(({ market }) => ({
                value: market.pair,
                label: market.pair,
                icon: {
                  type: "cryptocurrency",
                  size: "selectSize",
                  code: market.base,
                  base: market.quote,
                },
              }))
            : []
        }
      />
      <CandlestickChart
        loading={loading}
        error={error}
        candlestickChart={chartData?.candlestickChart}
        indicatorSignals={chartData?.indicatorSignals}
      />
    </div>
  );
};

export default IndicatorFormChartBlock;
