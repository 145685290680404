import React from "react";
import { Button } from "shared/ui/buttons";

const IndicatorFormBTNGroup = ({
  className,
  isEdit,
  toogleEdit,
  handleSubmit,
  formValues,
  isDisabled,
}) => {
  return (
    <div className={className}>
      <Button
        color='gray'
        className='mr-2 py-3'
        onClick={toogleEdit}
        type='button'
      >
        Cancel
      </Button>
      <Button
        color='blue'
        className='py-3'
        disabled={isDisabled}
        type='button'
        onClick={e => handleSubmit(formValues, e)}
      >
        {isEdit ? "Save" : "Add"}
      </Button>
    </div>
  );
};

export default IndicatorFormBTNGroup;
