import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAvailableMarkets,
  resetIndicatorsSignals,
} from "store/bots/actions";
import { currentConfigurationSelector } from "store/indicatorsConfiguration/selectors";
import { selectMarketsSignal } from "store/bots/selectors";
import {
  addDraftIndicator,
  editDraftIndicator,
  validateIndicator,
} from "store/strategies/actions";
import { setSelectedMarkets } from "store/bots/wizard/actions";
import { addSnackBar } from "store/snackbar/actions";

const useIndicatorSettings = (
  isEdit,
  getIndicatorsConfiguration,
  draft,
  editingIndicator,
  toogleBlock,
  isShowCreationBlock,
) => {
  const [indicatorType, setIndicatorType] = useState("");
  const [currentSettings, setCurrentSettings] = useState({});
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [coins, setCoins] = useState([]);

  const dispatch = useDispatch();

  const configurations = useSelector(state => state.indicatorsConfiguration);
  const currentConfiguration = useSelector(state =>
    currentConfigurationSelector(indicatorType)(state),
  );
  const subscription = useSelector(state => state.me.data.subscription);
  const wizardMarkets = useSelector(state => state.botWizard);
  const marketsSignal = useSelector(selectMarketsSignal);

  useEffect(() => {
    if (isShowCreationBlock) {
      setIndicatorType(currentConfiguration?.code);
    } else {
      setIndicatorType("");
    }
  }, [
    getIndicatorsConfiguration,
    setIndicatorType,
    isShowCreationBlock,
    currentConfiguration?.code,
  ]);

  useEffect(() => {
    if (isEdit) {
      setIndicatorType(editingIndicator.code);
    }
  }, [editingIndicator, isEdit, setIndicatorType]);

  useEffect(() => {
    if (indicatorType) {
      const fields = currentConfiguration.settings.reduce(
        (accumulator, currentValue) => {
          return {
            [currentValue.code]: currentValue.default || "",
            ...accumulator,
          };
        },
        {},
      );
      setCurrentSettings(fields);
    }
  }, [indicatorType, setCurrentSettings]);

  useEffect(() => {
    if (
      wizardMarkets?.allCoins &&
      !wizardMarkets?.coins?.length &&
      !coins?.length
    ) {
      dispatch(getAvailableMarkets(wizardMarkets?.investment.id, "?limit=500"));
    }
  }, [
    wizardMarkets?.allCoins,
    wizardMarkets?.investment.id,
    wizardMarkets?.coins?.length,
    dispatch,
    coins?.length,
  ]);

  useEffect(() => {
    if (marketsSignal?.data?.items) {
      const coinsArray = marketsSignal?.data?.items?.map(
        ({ market }) => market?.base,
      );
      setCoins(coinsArray);
    }
  }, [marketsSignal, dispatch, marketsSignal?.data?.items]);

  useEffect(() => {
    if (!wizardMarkets?.coins.length) {
      dispatch(setSelectedMarkets(coins));
    }
  }, [coins, wizardMarkets?.coins?.length, dispatch]);

  const onHandleResetChart = () => {
    dispatch(resetIndicatorsSignals());
    toogleBlock();
  };

  const handleSubmit = async values => {
    const payload = values?.code
      ? values
      : {
          ...values,
          code: configurations?.[0]?.code,
          ...configurations?.[0]?.settings,
        };

    const res = await dispatch(validateIndicator(payload));
    if (res.errors) {
      dispatch(addSnackBar("errors", res.message));
      return;
    } else {
      if (draft) {
        await dispatch(
          addDraftIndicator({
            ...values,
            code: currentConfiguration.code || configurations?.[0]?.code,
          }),
        );
      }
      if (isEdit) {
        await dispatch(
          editDraftIndicator({
            ...values,
            id: editingIndicator.id,
            code: currentConfiguration.code || configurations?.[0]?.code,
          }),
        );
      }

      if (!res.errors) {
        onHandleResetChart();
      }
    }
    dispatch(addSnackBar("success", res.message));
    toogleBlock();
    onHandleResetChart();
  };

  return {
    configurations,
    currentConfiguration,
    onHandleResetChart,
    handleSubmit,
    setSelectedMarket,
    selectedMarket,
    subscription,
    wizardMarkets,
    currentSettings,
    indicatorType,
    setIndicatorType,
  };
};

export default useIndicatorSettings;
