import React, { useState } from "react";
import { Tooltip } from "reactstrap";

import { ReactComponent as Information } from "assets/icons/general/information.svg";
import { INDICATORS_OPTIONS } from "views/bots/models/options";
import { SelectInput } from "shared/ui";
import { BadgeIndicatorSettingsBlock } from "shared/ui/badges";
import { IndicatorFormBTNGroup } from "./ui";
import {
  SrtledIndicatorFormSettingsCard,
  StyledCrownIcon,
  StyledIndicatorInputWrap,
} from "./index.styles";

const IndicatorFormSettingsBlock = ({
  formValues,
  setFieldValue,
  indicatorsConfiguration,
  submitted,
  configurations,
  subscription,
  onSetIndicatorType,
  setSubmitted,
  errors,
  isEdit,
  toogleEdit,
  handleSubmit,
}) => {
  const [isShow, setIsShow] = useState(false);

  const handleChooseIndicator = ({ value }) => {
    setFieldValue("code", value);
    onSetIndicatorType(value);
    setSubmitted(false);
  };

  const toggleShow = () => setIsShow(!isShow);

  const setType = value => {
    if (!value) {
      return "buy";
    }
    setFieldValue("type", value);
  };
  return (
    <div className='d-flex flex-column flex-md-row align-items-center p-0 w-100'>
      <div className='d-flex flex-column flex-column-reverse flex-md-row w-100'>
        <SrtledIndicatorFormSettingsCard
          required={formValues?.required}
          className='d-flex align-items-center justify-content-between mr-0 mr-md-4 mt-3 mt-md-0'
        >
          <div className='d-flex px-0'>
            <div
              className='position-relative necessary-signal mr-3'
              id={`indicator-1`}
              onClick={() => setFieldValue("required", !formValues?.required)}
            >
              <Information />
            </div>
            <Tooltip
              target={`indicator-1`}
              placement='top-start'
              isOpen={isShow}
              toggle={toggleShow}
              className='text-nowrap'
            >
              Necessary indicator for trade request
            </Tooltip>
            <BadgeIndicatorSettingsBlock
              arrayIndicatorsOptions={INDICATORS_OPTIONS}
              type={formValues?.type}
              setFieldValue={setType}
            />
          </div>
        </SrtledIndicatorFormSettingsCard>
        <StyledIndicatorInputWrap data-testid='indicator-input-wrapper'>
          <SelectInput
            className='w-100'
            hasError={submitted && errors.code}
            isSearchable
            withDisabledOptions
            placeholder='Type in...'
            name='code'
            options={configurations.map(({ code, free }) => ({
              value: code || "",
              label:
                indicatorsConfiguration.find(prop => prop.value === code)
                  .label || "",
              isDisabled:
                subscription?.type === "premium" ||
                subscription?.type === "standard"
                  ? false
                  : !free,
            }))}
            defaultValue={{
              value: isEdit
                ? formValues.code
                : indicatorsConfiguration?.[0]?.value,
              label: isEdit
                ? indicatorsConfiguration?.find(
                    ({ value }) => value === formValues.code,
                  )?.label || ""
                : indicatorsConfiguration?.[0]?.label || "",
            }}
            isOptionDisabled={option => option.isDisabled}
            onChange={handleChooseIndicator}
            disabledOptionIcon={<StyledCrownIcon className='crown-icon' />}
          />
          {submitted && errors.code ? (
            <div className='w-100'>
              <p className='text-danger mb-0'>{errors.code}</p>
            </div>
          ) : null}
        </StyledIndicatorInputWrap>
      </div>
      <IndicatorFormBTNGroup
        className='d-none d-md-flex justify-content-end mt-3 mt-md-0 '
        isEdit={isEdit}
        toogleEdit={toogleEdit}
        handleSubmit={handleSubmit}
        formValues={formValues}
        isDisabled={submitted}
      />
    </div>
  );
};

export default IndicatorFormSettingsBlock;
